import { store } from 'entrypoints/bootstrapOnClient';
import { getAmethystPageType } from 'helpers/analytics';

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/BrandEmailSignup.proto
export const evBrandEmailSignup = ({ brandName }) => ({
  brandEmailSignup: {
    sourcePage: getAmethystPageType(store.getState().pageView.pageType),
    brandName
  }
});
