import { fetchErrorMiddlewareMaybeJson } from 'middleware/fetchErrorMiddleware';
import { subscribeToBrandZSub, subscribeToListZSub } from 'apis/mafia';
import { trackError } from 'helpers/ErrorUtils';
import { selectMafiaConfig } from 'selectors/environment';

export function subscribeToBrandNotification(emailAddress, brandId) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return subscribeToBrandZSub(
      mafiaConfig,
      {
        emailAddress,
        brandIds: [brandId]
      },
      cookies
    )
      .then(fetchErrorMiddlewareMaybeJson)
      .then(() => true)
      .catch(e => {
        trackError('NON-FATAL', 'Could not subscribe to brand notifications.', e);
      });
  };
}

export function subscribeToLandingList(emailAddress, listId) {
  return (dispatch, getState) => {
    const state = getState();
    const subsiteId = 1;
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return subscribeToListZSub(
      mafiaConfig,
      {
        subsiteId,
        emailAddress,
        listIds: [listId]
      },
      cookies
    )
      .then(fetchErrorMiddlewareMaybeJson)
      .then(() => true)
      .catch(e => {
        trackError('NON-FATAL', 'Could not subscribe to list.', e);
      });
  };
}
